import { useTheme } from "@emotion/react";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { formatDate } from "../Functions/formatDate";
import formatTemperature from "../Functions/formatTemperature";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GraphBaseAsset from "./Graphs/GraphBaseAsset";

export default function AssetMetric({ metric, asset }) {
  const theme = useTheme();

  let location = null;
  let iconUnit = null;

  if (metric.metric_type_id === 1) {
    iconUnit = "°" + localStorage.getItem("temperature_unit");
  }
  if (metric.metric_type_id === 3) {
    let values = JSON.parse(metric.value);
    location = values["geocode"];
  }

  return (
    <>
      <Grid item xs={3} sx={{ mb: 1 }}>
        <Card sx={{ display: "flex", flexFlow: "column", minHeight: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: 1,
            }}
          >
            <Box>
              <Typography variant="h7" sx={{ fontWeight: "600" }}>
                {metric.label}
              </Typography>
              <br />
              <Typography variant="caption">
                {formatDate(metric.time_updated)}
              </Typography>
            </Box>
          </Box>
          <Stack
            spacing={2}
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
            sx={{
              p: 2,
              pt: 0,
              //full height of the card
              flex: "1 1 auto",
            }}
          >
            <FontAwesomeIcon
              icon={
                metric.metric_type_id === 1
                  ? "thermometer-half"
                  : metric.metric_type_id === 2
                  ? "tachometer-alt"
                  : "map-marker-alt"
              }
              size={"4x"}
              color={theme.palette.primary.main}
              sx={{ flexGrow: 1 }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Typography
                variant={"h6"}
                sx={{
                  alignSelf: "center",
                  fontSize:
                    metric.metric_type_id === 3 || metric.metric_type_id === 4
                      ? "1.2rem"
                      : "4rem",
                  mr: 1,
                }}
              >
                {metric.metric_type_id === 1
                  ? formatTemperature(metric.value, true)
                  : null}
                {metric.metric_type_id === 2 ? metric.value : null}
                {metric.metric_type_id === 3
                  ? location
                  : metric.metric_type_id === 4
                  ? metric.value
                  : metric.metric_type_id === 5
                  ? metric.value
                  : metric.metric_type_id === 6
                  ? metric.value
                  : null}
              </Typography>
              {iconUnit ? (
                <Typography variant="p">{iconUnit}</Typography>
              ) : null}
            </Box>
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={9} sx={{ mb: 1 }}>
        <GraphBaseAsset title={metric.label} value={metric.value} />
      </Grid>
    </>
  );
}
