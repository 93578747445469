import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import fetchAssets from "../../Functions/fetchAssets";
import { useSelector } from "react-redux";
import { putData } from "../../API/Put";

export default function AssetEditDialog(props) {
  const { open, handleClose, asset } = props;

  const { handleSubmit, control, reset } = useForm();

  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const { t } = useTranslation("common");

  const onSubmit = async (data) => {
    const { name, description } = data;

    let jsonData = {
      name: name,
      description: description,
      organisation_id: selectedOrganisation.id,
    };

    try {
      await putData(`/asset/${asset.id}`, jsonData);
      updateSnackbarMessage(t("assets.asset_updated"));
    } catch (error) {
      updateSnackbarMessage("Error: " + error.message);
      console.log(error);
    }
    await fetchAssets(selectedOrganisation.id);
    handleClose();
  };

  const validationLabels = {
    name: {
      required: t("assets.name_required"),
    },
    description: {
      required: t("assets.description_required"),
    },
  };

  useEffect(() => {
    if (asset) {
      reset({
        name: asset.name,
        description: asset.description,
      });
    }
  }, [asset, reset]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t("assets.edit_asset_title")}</DialogTitle>
        <DialogContent>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: true,
              }}
              defaultValue="New Asset"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={error !== undefined}
                  helperText={error ? validationLabels.name[error.type] : ""}
                  label={t("common.name")}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={error !== undefined}
                  helperText={
                    error ? validationLabels.description[error.type] : ""
                  }
                  label={t("common.description")}
                  variant="outlined"
                  fullWidth
                  sx={{ mt: 2 }}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            {t("common.update")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
