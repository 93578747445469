import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import TemperatureGuague from "../Guages/Temperature";
import DeviceActionLogs from "../Cards/Alerts";
import HumidityGuague from "../Guages/Humidity";
import TemperatureGraph from "../Graphs/Temperature";
import HumidityGraph from "../Graphs/Humidity";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import FrameList from "../FrameList";
import Rssi from "../Cards/Rssi";
import { useSelector } from "react-redux";
import MoldIndex from "../Cards/MoldIndex";
import MoldIndexGraph from "../Graphs/MoldIndexGraph";

export default function TempHumidityNbIoT(props) {
  let { isShare } = props;

  const [showMoldIndex, setShowMoldIndex] = React.useState(false);
  const deviceProfile = useSelector((state) => state.deviceProfile);

  useEffect(() => {
    if (deviceProfile) {
      if (typeof deviceProfile === "string") {
        let deviceProfileDecoded = JSON.parse(deviceProfile);

        if (!deviceProfileDecoded.irisTempHumidity) return;

        if (deviceProfileDecoded.irisTempHumidity.showMoldIndex) {
          setShowMoldIndex(true);
        } else {
          setShowMoldIndex(false);
        }
      }
    }
  }, [deviceProfile]);

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 1 }}>
          <BatteryStatus field="bat" />
        </Grid>
        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 1 }}>
          <Rssi />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={3} sx={{ mb: 1 }}>
            <LastAlert />
          </Grid>
        )}

        {showMoldIndex && (
          <>
            <Grid item xs={12} md={4} sx={{ mb: 1 }}>
              <MoldIndex />
            </Grid>

            <Grid item xs={12} md={8} sx={{ mb: 1 }}>
              <MoldIndexGraph />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <TemperatureGuague temperatureValue="temp" />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 1 }}>
          <TemperatureGraph useIsXL={false} temperatureValue="temp" />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <HumidityGuague humidityValue="RH" />
        </Grid>
        <Grid item xs={12} md={9} sx={{ mb: 1 }}>
          <HumidityGraph humidityValue="RH" />
        </Grid>

        <Grid item xs={12} md={!isShare ? 8 : 12} sx={{ mb: 1 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={4}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
