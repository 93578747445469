import { useSelector } from "react-redux";
import DataTableBase from "../DataTableBase";
import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import formatTemperature from "../../Functions/formatTemperature";
import AssetDeleteDialog from "../Dialogs/assetDelete";
import { useState } from "react";
import AssetEditDialog from "../Dialogs/assetEdit";

export default function AssetsTable() {
  const assets = useSelector((state) => state.assets);
  const { t } = useTranslation("common");

  const [deleteAssetDialogOpen, setDeleteAssetDialogOpen] = useState(false);
  const [editAssetDialogOpen, setEditAssetDialogOpen] = useState(false);

  const [assetId, setAssetId] = useState(null);
  const [asset, setAsset] = useState({});

  //columns for devices for data table
  const columns = [
    {
      name: "id",
      label: t("common.ID"),
      options: {
        display: false,
      },
    },
    {
      name: "name",
      options: {
        label: t("common.name"),
        customBodyRender: (value, tableMeta, meta) => {
          return (
            <Typography
              onClick={() => {
                window.location.href = "/asset/" + tableMeta.rowData[0];
              }}
              className="cursor-pointer"
              variant="body1"
              color="primary"
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "metrics",
      //set max width to 200px

      label: t("common.metrics"),
      options: {
        setCellProps: () => ({
          style: {
            maxWidth: "300px",
          },
        }),
        customBodyRender: (value) => {
          //value is an array of metrics, with type and value
          if (!value) return null;

          return value.map((metric, index) => (
            <Chip
              key={index}
              label={
                metric.metric_type_id === 3
                  ? "Location: " + JSON.parse(metric.value).geocode
                  : metric.metric_type_id === 1
                  ? "Temperature: " + formatTemperature(metric.value)
                  : metric.label + ": " + metric.value
              }
              sx={{ mr: 1, mb: 1 }}
              variant="outlined"
              color="primary"
            />
          ));
        },
      },
    },

    //options
    {
      name: "options",
      label: "Options",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Stack direction="row" spacing={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setAssetId(tableMeta.rowData[0]);
                  setAsset(
                    assets.find(
                      (asset) =>
                        parseInt(asset.id) === parseInt(tableMeta.rowData[0])
                    )
                  );
                  setEditAssetDialogOpen(true);
                }}
              >
                Edit
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setAssetId(tableMeta.rowData[0]);
                  setAsset(
                    assets.find(
                      (asset) =>
                        parseInt(asset.id) === parseInt(tableMeta.rowData[0])
                    )
                  );
                  setDeleteAssetDialogOpen(true);
                }}
              >
                Delete
              </Button>
            </Stack>
          );
        },
      },
    },
  ];

  return (
    <Box sx={{ mt: 1 }}>
      <DataTableBase title={t("assets.name")} data={assets} columns={columns} />
      <AssetDeleteDialog
        open={deleteAssetDialogOpen}
        handleClose={() => setDeleteAssetDialogOpen(false)}
        assetId={assetId}
        asset={asset}
      />
      <AssetEditDialog
        open={editAssetDialogOpen}
        handleClose={() => setEditAssetDialogOpen(false)}
        assetId={assetId}
        asset={asset}
      />
    </Box>
  );
}
