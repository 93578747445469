import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";
import formatTemperature from "../../Functions/formatTemperature";
import formatSpeed from "../../Functions/formatSpeed";
import { useSelector } from "react-redux";

export default function GraphBaseAsset(props) {
  const [history, setHistory] = React.useState([]);
  const [minValue, setMinValue] = React.useState(0);
  const [maxValue, setMaxValue] = React.useState(0);

  const deviceHistory = useSelector((state) => state.assetHistory);

  const {
    value,
    title,
    unit = "",
    min,
    max,
    type,
    trimValue = false,
    truefalse,
    useIsXL = true,
    trueV = "True",
    falseV = "False",
    trimNumber = 1,
    aspect = 10,
    formatter = null,
    graphValueFormatter = (value) => value,
  } = props;

  const theme = useTheme();

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      //grab all data objects from deviceHistory, parse them, and add them to history
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;

        let valueTemp = 0;

        valueTemp = jsonData[value];

        //if the valueFound is an array, then only reutrn the first value
        if (Array.isArray(valueTemp)) {
          valueTemp = valueTemp[0];
        }

        if (valueTemp !== undefined && valueTemp !== null && valueTemp !== "") {
          if (trimValue && typeof valueTemp === "string") {
            //remove the last 1 character from the value and convert it to an number
            valueTemp = Number(valueTemp.slice(0, -trimNumber));
          }

          if (type === "temperature") {
            valueTemp = formatTemperature(valueTemp, true);
          }

          if (value === "speedKmph") {
            valueTemp = formatSpeed(valueTemp, false);
          }

          if (truefalse) {
            valueTemp = valueTemp ? trueV : falseV;
          }
        }

        tempHistory.push({
          Date: time_created,
          [value]: valueTemp,
        });

        if (min === undefined && max === undefined) {
          //get min and max value from tempHistory
          setMinValue(Math.min(...tempHistory.map((o) => o[value])));
          setMaxValue(Math.max(...tempHistory.map((o) => o[value])));
        } else {
          setMinValue(min);
          setMaxValue(max);
        }
      });

      setHistory(tempHistory);
    } catch (error) {
      console.log(error);
    }
  }, [
    deviceHistory,
    value,
    useIsXL,
    min,
    max,
    type,
    trimValue,
    truefalse,
    trueV,
    falseV,
    trimNumber,
    formatter,
    graphValueFormatter,
  ]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title={title ? title + " History" : " History"} />
      <CardContent>
        <ResponsiveContainer aspect={aspect} width="100%">
          <AreaChart
            data={history}
            margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <defs>
              <linearGradient id="Temperature" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis
              dataKey={value}
              type={truefalse ? "category" : "number"}
              yAxisId="1"
              //use domain to set min and max temperature, if min temperature is a minus, add -5 to it
              domain={[minValue, maxValue]}
              //round the label to 3 decimal places
            />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
              //format label to capitalize first letter of each word and format value to use celsius symbol and format date

              formatter={(value, name) => {
                let newName = name;

                newName = title;

                //replace _ with spaces, captilise first letter of each word
                newName = newName.replace(/_/g, " ");
                newName = newName.replace(/\b\w/g, (l) => l.toUpperCase());

                return [value + unit, newName];
              }}
            />
            <Area
              yAxisId="1"
              dataKey={value}
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill="url(#Temperature)"
              strokeWidth={2}
              dominantBaseline={"middle"}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
