import fetchData from "../API/Fetch";
import { store } from "../store";
export default async function fetchAssetHistory(assetID) {
  if (assetID === undefined || assetID === 0) return;
  let result = await fetchData(`/asset/history/${assetID}`);

  store.dispatch({
    type: "assetHistory/update",
    payload: result ? result : [],
  });
  return result;
}
