import React, { useEffect } from "react";

import FrameList from "../FrameList";
import { Grid } from "@mui/material";
import TimelineCard from "../Cards/timeline";
import DeviceInfo from "../Cards/DeviceInfo";
import { useSelector } from "react-redux";
import DeviceActionLogs from "../Cards/Alerts";
import TemperatureGuague from "../Guages/Temperature";
import TemperatureGraph from "../Graphs/Temperature";
import HumidityGuague from "../Guages/Humidity";
import HumidityGraph from "../Graphs/Humidity";

export default function Tracker(props) {
  const isMobile = useSelector((state) => state.isMobile);
  const { isShare } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);

  const [hasTemp, setHasTemp] = React.useState(false);
  const [hasHum, setHasHum] = React.useState(false);

  //run a check to see if the device has a humidity or temperature field and display the appropriate graph
  useEffect(() => {
    if (!deviceHistory) {
      return;
    }

    let tempFound = deviceHistory.map((item) => {
      let data = JSON.parse(item.data);

      let hasKey = Object.keys(data).includes("temperature");

      if (hasKey) {
        return data.temperature !== null;
      }

      return false;
    });

    let humFound = deviceHistory.map((item) => {
      //get the data json field, decode it and check for key "humidity"
      let data = JSON.parse(item.data);

      let hasKey = Object.keys(data).includes("humidity");

      //check key value is not null
      if (hasKey) {
        return data.humidity !== null;
      }

      return false;
    });

    setHasTemp(tempFound.includes(true));
    setHasHum(humFound.includes(true));
  }, [deviceHistory]);

  return (
    <>
      {
        //history and graphs
      }
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <TimelineCard />
        </Grid>

        {isMobile && (
          <Grid item xs={12} md={12} sx={{ mb: 1 }}>
            <DeviceInfo />
          </Grid>
        )}

        {hasTemp && (
          <>
            <Grid item xs={12} md={3} xxl={3} sx={{ mb: 1 }}>
              <TemperatureGuague />
            </Grid>

            <Grid item xs={12} md={9} xxl={9} sx={{ mb: 1 }}>
              <TemperatureGraph useIsXL={false} />
            </Grid>
          </>
        )}

        {hasHum && (
          <>
            <Grid item xs={12} md={3} xxl={3} sx={{ mb: 1 }}>
              <HumidityGuague />
            </Grid>

            <Grid item xs={12} md={9} xxl={9} sx={{ mb: 1 }}>
              <HumidityGraph useIsXL={false} />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <FrameList />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={6}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
